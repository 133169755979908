import { round, setMillion, findSum, makeArrayBudget, makeArrayGroup, makeArrayBudgetPr, makeArrayBudgetGroup, makeArrayBudgetGroupPr, makeArrayBudgetGroupBar, findName, colorsArr, colorsGroupArr, makeSumBudget, makeArrayGroupNames } from '../helpers/chart_helpers';
import { makeArrayGroupDataFromXL, makeArrayBudgetGroupDataFromXL, makeArrayBudgetGroupPrDataFromXL, findMainMediaByBudgetDataFromXL } from '../helpers/chart_helpers_data_from_XL';
import { setStatus } from './app_reducer';
import { setTargetAudience, setCampaignType, setGeo, setAllMedia, setPeriod, setBudget_Coverage, setFrequency, setGroupCheckedArr, setMediaIsSelected, setBUDGET_FOR_NEXT_CHARTS, setCopySelectedMedia, setAud_totalCurrentValue, setMonth, setIsMonthVisible, setIsRestrictions } from '../bll/inputParameters_reducer';
import { set_investment_growth_graph_data, set_investment_coverage_graph_data, setTs_investment_growth_chart_grouping_checked, setTs_investment_growth_chart_unit_checked, setTs_investment_coverage_chart_grouping_checked, setTs_investment_coverage_chart_unit_checked, setUnitGrowth, setUnitCoverage, setIsGrowthChartRendered, setIsCoverageChartRendered, setKnowledgeArr } from '../bll/charts_part2_reducer';

const SET_OPTIC_MEDIA_MIX_GRAPH_DATA = 'SET_OPTIC_MEDIA_MIX_GRAPH_DATA';
const SET_DONUT_DATA = 'SET_DONUT_DATA';
const SET_CHARTS_DATA = 'SET_CHARTS_DATA';
const SET_CONTRIBUTION_DATA = 'SET_CONTRIBUTION_DATA';
const SET_MAIN_MEDIA = 'SET_MAIN_MEDIA';
const SET_UNIT_DONUT = 'SET_UNIT_DONUT';
const SET_UNIT_OPTIC = 'SET_UNIT_OPTIC';
const SET_ts_pieChart_grouping_checked = 'SET_ts_pieChart_grouping_checked';
const SET_ts_pieChart_unit_checked = 'SET_ts_pieChart_unit_checked';
const SET_ts_contribution_chart_grouping_checked = 'SET_ts_contribution_chart_grouping_checked';
const SET_ts_contribution_chart_unit_checked = 'SET_ts_contribution_chart_unit_checked';
const SET_ts_optic_media_mix_chart_grouping_checked = 'SET_ts_optic_media_mix_chart_grouping_checked';
const SET_ts_optic_media_mix_chart_unit_checked = 'SET_ts_optic_media_mix_chart_unit_checked';
const SET_DATA_FROM_XL = 'SET_DATA_FROM_XL';
const SET_IS_CHARTS_UPLOAD = 'SET_IS_CHARTS_UPLOAD';
const SET_COLORS_ARRAY = 'SET_COLORS_ARRAY';
const SET_COLORS_GROUP_ARRAY = 'SET_COLORS_GROUP_ARRAY';

const initialState = {
  chartsData: [],
  contributionOfEachMedia_data: {},
  optic_media_mix_graph_data: {},
  donutData: {},
  mainMedia: null,
  unitDonut: '₽',
  unitOptic: '₽',
  ts_pieChart_grouping_checked: true,
  ts_pieChart_unit_checked: true,
  ts_contribution_chart_grouping_checked: true,
  ts_contribution_chart_unit_checked: true,
  ts_optic_media_mix_chart_grouping_checked: true,
  ts_optic_media_mix_chart_unit_checked: true,
  dataFromXL: [],
  isChartsUpload: false,
};

export const charts_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPTIC_MEDIA_MIX_GRAPH_DATA:
      return { ...state, optic_media_mix_graph_data: action.optic_media_mix_graph_data };
    case SET_DONUT_DATA:
      return { ...state, donutData: action.donutData };
    case SET_CHARTS_DATA:
      return { ...state, chartsData: action.chartsData };
    case SET_CONTRIBUTION_DATA:
      return { ...state, contributionOfEachMedia_data: action.contributionOfEachMedia_data };
    case SET_MAIN_MEDIA:
      return { ...state, mainMedia: action.mainMedia };
    case SET_UNIT_DONUT:
      return { ...state, unitDonut: action.unitDonut };
    case SET_UNIT_OPTIC:
      return { ...state, unitOptic: action.unitOptic };
    case SET_ts_pieChart_grouping_checked:
      return { ...state, ts_pieChart_grouping_checked: action.ts_pieChart_grouping_checked };
    case SET_ts_pieChart_unit_checked:
      return { ...state, ts_pieChart_unit_checked: action.ts_pieChart_unit_checked };
    case SET_ts_contribution_chart_grouping_checked:
      return { ...state, ts_contribution_chart_grouping_checked: action.ts_contribution_chart_grouping_checked };
    case SET_ts_contribution_chart_unit_checked:
      return { ...state, ts_contribution_chart_unit_checked: action.ts_contribution_chart_unit_checked };
    case SET_ts_optic_media_mix_chart_grouping_checked:
      return { ...state, ts_optic_media_mix_chart_grouping_checked: action.ts_optic_media_mix_chart_grouping_checked };
    case SET_ts_optic_media_mix_chart_unit_checked:
      return { ...state, ts_optic_media_mix_chart_unit_checked: action.ts_optic_media_mix_chart_unit_checked };
    case SET_DATA_FROM_XL:
      return { ...state, dataFromXL: action.dataFromXL };
    case SET_IS_CHARTS_UPLOAD:
      return { ...state, isChartsUpload: action.isChartsUpload };
    case SET_COLORS_ARRAY:
      return { ...state, colorsArray: action.colorsArray };
    case SET_COLORS_GROUP_ARRAY:
      return { ...state, colorsGroupArray: action.colorsGroupArray };
    default:
      return state;
  }
};

// action creators
export const setOpticMediaMixGraphData = optic_media_mix_graph_data => ({ type: SET_OPTIC_MEDIA_MIX_GRAPH_DATA, optic_media_mix_graph_data });
export const setDonutData = donutData => ({ type: SET_DONUT_DATA, donutData });
export const setAllChartsData = chartsData => ({ type: SET_CHARTS_DATA, chartsData });
export const setContributionOfEachMedia_data = contributionOfEachMedia_data => ({ type: SET_CONTRIBUTION_DATA, contributionOfEachMedia_data });
export const setMainMedia = mainMedia => ({ type: SET_MAIN_MEDIA, mainMedia });
export const setUnitDonut = unitDonut => ({ type: SET_UNIT_DONUT, unitDonut });
export const setUnitOptic = unitOptic => ({ type: SET_UNIT_OPTIC, unitOptic });
export const setTs_pieChart_grouping_checked = ts_pieChart_grouping_checked => ({ type: SET_ts_pieChart_grouping_checked, ts_pieChart_grouping_checked });
export const setTs_pieChart_unit_checked = ts_pieChart_unit_checked => ({ type: SET_ts_pieChart_unit_checked, ts_pieChart_unit_checked });
export const setTs_contribution_chart_grouping_checked = ts_contribution_chart_grouping_checked => ({ type: SET_ts_contribution_chart_grouping_checked, ts_contribution_chart_grouping_checked });
export const setTs_contribution_chart_unit_checked = ts_contribution_chart_unit_checked => ({ type: SET_ts_contribution_chart_unit_checked, ts_contribution_chart_unit_checked });
export const setTs_optic_media_mix_chart_grouping_checked = ts_optic_media_mix_chart_grouping_checked => ({ type: SET_ts_optic_media_mix_chart_grouping_checked, ts_optic_media_mix_chart_grouping_checked });
export const setTs_optic_media_mix_chart_unit_checked = ts_optic_media_mix_chart_unit_checked => ({ type: SET_ts_optic_media_mix_chart_unit_checked, ts_optic_media_mix_chart_unit_checked });
export const setDataFromXL = dataFromXL => ({ type: SET_DATA_FROM_XL, dataFromXL });
export const setIsChartsUpload = isChartsUpload => ({ type: SET_IS_CHARTS_UPLOAD, isChartsUpload });
export const setColorsArray = colorsArray => ({ type: SET_COLORS_ARRAY, colorsArray });
export const setColorsGroupArray = colorsGroupArray => ({ type: SET_COLORS_GROUP_ARRAY, colorsGroupArray });

//thunk creators
export const updateCharts = dataFromXL => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const budget_DataFromXL = `${round(
    setMillion(
      +dataFromXL
        .filter(item => item.Media3)
        .map(item => item.Option5)
        .reduce(function (accumulator, currentValue) {
          return accumulator + +currentValue;
        }, 0)
    )
  )} млн`;

  const updatedInputData = dataFromXL.filter(item => item.targetAudience)[0];
  const Aud_totalCurrentValue_dataFromXL = +updatedInputData.Aud_totalCurrentValue;
  const updatedInputMedia = dataFromXL
    .filter(item => item.media)
    .map(item => ({
      media: item.media,
      group: item.group,
      CPP: +item.CPP,
      // CPP: (+item.CPP * 100) / Aud_totalCurrentValue_dataFromXL,
      coeff: +item.coeff * 100,
      finishBudget: +item.finishBudget,
      startBudget: +item.startBudget,
      isChecked: item.isChecked === 'FALSE' ? false : true,
      k: +item.k,
    }));
  const unitGrowth = getState().charts2.unitGrowth;
  const unitOptic = getState().charts.unitOptic;
  const updatedGrowthChartData = dataFromXL.filter(item => item.Media4);
  const updatedCoverageChartData = dataFromXL.filter(item => item.Media5);

  const isUpdatedDataFromAllCharts = dataFromXL.find(item => item.Media4);
  dispatch(setMediaIsSelected(true));
  dispatch(setAud_totalCurrentValue(Aud_totalCurrentValue_dataFromXL));

  const datasetMediaDefault_dataFromXL = dataFromXL
    .filter(item => item.mediaDefault)
    .map(item => ({
      media: item.mediaDefault,
      group: item.group,
      CPP: +item.CPP,
      // CPP: (+item.CPP * 100) / Aud_totalCurrentValue_dataFromXL,
      coeff: +item.coeff,
      finishBudget: +item.finishBudget,
      startBudget: +item.startBudget,
      isChecked: item.isChecked === 'FALSE' ? false : true,
      k: +item.k,
    }));
  dispatch(setCopySelectedMedia(datasetMediaDefault_dataFromXL));

  const dataSetGroupCheckedArr_dataFromXL = dataFromXL
    .filter(item => item.groupCheckbox)
    .map(item => ({
      [item.groupCheckbox]: item.isChecked === 'FALSE' ? false : true,
    }));
  dispatch(setAllMedia(updatedInputMedia));
  dispatch(setGroupCheckedArr(dataSetGroupCheckedArr_dataFromXL));
  dispatch(setTargetAudience(updatedInputData.targetAudience));
  dispatch(setFrequency(updatedInputData.frequency));
  dispatch(setPeriod(+updatedInputData.period));
  dispatch(setMonth(updatedInputData.month));
  dispatch(setIsMonthVisible(+updatedInputData.isMonthVisible));
  dispatch(setIsRestrictions(+updatedInputData.isRestrictions));

  const budget_coverage = getState().input.budget_coverage;
  const updatedBudg = budget_coverage.map(item => {
    const updatedItem = { ...item };
    updatedItem.value = +updatedInputData.budget_coverage;
    updatedItem.isChecked = true;
    const otherItem = { ...item };
    otherItem.value = 0;
    otherItem.isChecked = false;
    return item.name === updatedInputData.budget_coverageNAME ? updatedItem : otherItem;
  });
  dispatch(setBudget_Coverage(updatedBudg));

  const campaignType = getState().input.campaignType;
  const updatedCamp = campaignType.map(item => {
    const updatedItem = { ...item };
    updatedItem.isChecked = true;
    const otherItem = { ...item };
    otherItem.isChecked = false;
    return item.name === updatedInputData.campaignType ? updatedItem : otherItem;
  });
  dispatch(setCampaignType(updatedCamp));

  const geo = getState().input.geo;
  const updatedGEO = geo.map(item => {
    const updatedItem = { ...item };
    updatedItem.isChecked = true;
    updatedItem.geo = updatedInputData.geo;
    const otherItem = { ...item };
    otherItem.isChecked = false;
    return item.geo === updatedInputData.geo ? updatedItem : otherItem;
  });
  dispatch(setGeo(updatedGEO));

  const coverages = [];
  const donutData = dataFromXL.filter(item => item.Media1);

  const donutMedia = donutData.map(item => item.Media1);
  const donutBudget = donutData.map(item => item.Budget);
  const INCREACHMEDIA = dataFromXL.filter(item => item.Media2).map(media => media.MainMedia);
  const tv = INCREACHMEDIA.find(media => media === 'TV');
  const mainMediaByBudget = findMainMediaByBudgetDataFromXL(donutData, donutBudget);
  const firstMainMedia = tv ? tv : mainMediaByBudget;
  dispatch(setMainMedia(firstMainMedia));

  const mediaArr = updatedInputMedia;
  const allMediaName = mediaArr.map(item => item.media);
  const allColorsArray = allMediaName.map((key, i) => {
    return {
      [key]: colorsArr[i],
    };
  });
  const allMediaGroup = makeArrayGroupNames(mediaArr.map(item => item.group));
  const allColorsGroupArray = allMediaGroup.map((key, i) => {
    return {
      [key]: colorsGroupArr[i],
    };
  });

  const donutAllColorsArray = donutMedia.map((key, i) => {
    return {
      [key]: Object.values(findName(key, allColorsArray))[0],
    };
  });

  const contributionData = dataFromXL.filter(item => item.Media2).filter(item => item.MainMedia === firstMainMedia);
  contributionData.sort(function (x, y) {
    return x.Media2 === x.MainMedia ? -1 : y.Media2 === y.MainMedia ? 1 : 0;
  });
  const contributionMedia = contributionData.map(item => item.Media2);
  const contributionBudget = contributionData.map(item => item.Budget);
  const opticData = dataFromXL.filter(item => item.Media3);
  const opticReach = dataFromXL.filter(item => item.Reach);
  const opticMedia = opticData.map(item => item.Media3);
  opticReach.map(item => {
    coverages.push(item.Option1, item.Option2, item.Option3, item.Option4, item.Option5, item.Option6, item.Option7, item.Option8, item.Option9);
    return item;
  });

  const contributionAllColorsArray = contributionMedia.map((key, i) => {
    return {
      [key]: Object.values(findName(key, allColorsArray))[0],
    };
  });
  dispatch(setColorsArray(allColorsArray));
  dispatch(setColorsGroupArray(allColorsGroupArray));

  const mediaArrayOptic = opticMedia.map((key, index) => {
    return {
      [key]: [],
      name: key,
      color: Object.values(findName(key, allColorsArray))[0],
    };
  });
  for (let i = 0; i < opticMedia.length; i++) {
    const findNameMedia = findName(opticMedia[i], mediaArrayOptic);
    const findObj = opticData.find(obj => obj.Media3 === opticMedia[i]);
    Object.values(findNameMedia)[0].push(findObj.Option1, findObj.Option2, findObj.Option3, findObj.Option4, findObj.Option5, findObj.Option6, findObj.Option7, findObj.Option8, findObj.Option9);
  }
  const donutDataForBudgetForNextCharts = JSON.stringify(
    donutData
      .map((key, index) => {
        return {
          Budget: +key.Budget,
          BudgetPr: +key.BudgetPr,
          group: key.Group,
          name: key.Media1,
        };
      })
      // eslint-disable-next-line no-sequences
      .reduce((acc, n) => ((acc[n.name] = n), acc), {})
  );
  dispatch(setBUDGET_FOR_NEXT_CHARTS(donutDataForBudgetForNextCharts));

  const growthData = dataFromXL.filter(item => item.Media4);
  const coveragesGrowth = [];
  const growthReach = dataFromXL.filter(item => item.ReachGrowth);
  growthReach.map(item => {
    coveragesGrowth.push(item['1'], item['2'], item['3'], item['4'], item['5'], item['6'], item['7'], item['8'], item['9']);
    return item;
  });
  const updatedKnowledgeArr = dataFromXL.find(item => item.Knowledge) ? Object.values(dataFromXL.filter(item => item.Knowledge)[0]) : []
  dispatch(setKnowledgeArr(updatedKnowledgeArr))

  const labelsArr = [];
  let sum1 = 0;
  let sum2 = 0;
  let sum3 = 0;
  let sum4 = 0;
  let sum5 = 0;
  let sum6 = 0;
  let sum7 = 0;
  let sum8 = 0;
  let sum9 = 0;
  for (let i = 0; i < growthData.length; i++) {
    sum1 += +growthData[i]['1'];
    sum2 += +growthData[i]['2'];
    sum3 += +growthData[i]['3'];
    sum4 += +growthData[i]['4'];
    sum5 += +growthData[i]['5'];
    sum6 += +growthData[i]['6'];
    sum7 += +growthData[i]['7'];
    sum8 += +growthData[i]['8'];
    sum9 += +growthData[i]['9'];
  }
  labelsArr.push(sum1, sum2, sum3, sum4, sum5, sum6, sum7, sum8, sum9);
  const mediaArrayGrowth = donutMedia.map((key, index) => {
    return {
      [key]: [],
      name: key,
      color: Object.values(findName(key, allColorsArray))[0],
    };
  });
  for (let i = 0; i < donutMedia.length; i++) {
    const findNameMedia = findName(donutMedia[i], mediaArrayGrowth);
    const findObj = growthData.find(obj => obj.Media4 === donutMedia[i]);
    findObj && Object.values(findNameMedia)[0].push(findObj['1'], findObj['2'], findObj['3'], findObj['4'], findObj['5'], findObj['6'], findObj['7'], findObj['8'], findObj['9']);
  }
  const coverageData = dataFromXL.filter(item => item.Media5);
  const coverageReach = dataFromXL.filter(item => item.ReachCovarage);
  const labelsArrCoverage = [];
  coverageReach.map(item => {
    labelsArrCoverage.push(item['1'], item['2'], item['3'], item['4'], item['5'], item['6'], item['7'], item['8'], item['9']);
    return item;
  });

  const mediaArrayCoverage = donutMedia.map((key, index) => {
    return {
      [key]: [],
      name: key,
      color: Object.values(findName(key, allColorsArray))[0],
    };
  });
  for (let i = 0; i < donutMedia.length; i++) {
    const findNameMedia = findName(donutMedia[i], mediaArrayCoverage);
    const findObj = coverageData.find(obj => obj.Media5 === donutMedia[i]);
    findObj && Object.values(findNameMedia)[0].push(findObj['1'], findObj['2'], findObj['3'], findObj['4'], findObj['5'], findObj['6'], findObj['7'], findObj['8'], findObj['9']);
  }

  dispatch(setTs_pieChart_unit_checked(true));
  dispatch(setTs_pieChart_grouping_checked(true));
  dispatch(setTs_contribution_chart_unit_checked(true));
  dispatch(setTs_contribution_chart_grouping_checked(true));
  dispatch(setTs_optic_media_mix_chart_unit_checked(true));
  dispatch(setTs_optic_media_mix_chart_grouping_checked(true));
  dispatch(setUnitDonut('₽'));
  dispatch(setUnitOptic('₽'));

  dispatch(setTs_investment_growth_chart_grouping_checked(true));
  dispatch(setTs_investment_growth_chart_unit_checked(true));
  dispatch(setTs_investment_coverage_chart_grouping_checked(true));
  dispatch(setTs_investment_coverage_chart_unit_checked(true));
  dispatch(setUnitGrowth('₽'));
  dispatch(setUnitCoverage('₽'));

  if (updatedGrowthChartData.length === 0) {
    dispatch(setIsGrowthChartRendered(false));
    dispatch(set_investment_growth_graph_data({}));
  } else if (updatedGrowthChartData.length > 0) {
    dispatch(setIsGrowthChartRendered(true));
  }
  if (updatedCoverageChartData.length === 0) {
    dispatch(setIsCoverageChartRendered(false));
    dispatch(set_investment_coverage_graph_data({}));
  } else if (updatedCoverageChartData.length > 0) {
    dispatch(setIsCoverageChartRendered(true));
  }

  dispatch(
    setContributionOfEachMedia_data({
      labels: contributionMedia,

      datasets: [
        {
          label: 'Contribution',
          data: contributionBudget,
          backgroundColor: contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
          hoverOffset: 4,
        },
      ],
    })
  );
  dispatch(
    setOpticMediaMixGraphData({
      labels: [budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL],
      datasets: [
        {
          type: 'line',
          tooltip: {
            callbacks: {
              label: function (TooltipItem) {
                return TooltipItem.dataset.label + ` ${updatedInputData.frequency}: ` + round(TooltipItem.parsed.y, 2) + ` %`;
              },
            },
          },
          label: 'Охват',
          borderColor: 'black',
          backgroundColor: 'black',
          borderWidth: 2,
          fill: false,
          data: coverages,
          yAxisID: 'ohvat',
          y2axis: true,
          datalabels: {
            display: false,
          },
        },
        ...mediaArrayOptic.map(array => ({
          type: 'bar',
          tooltip: {
            callbacks: {
              label: function (TooltipItem) {
                const changedTooltipItem = unitOptic === '₽' ? round(setMillion(TooltipItem.parsed.y), 1) : round(TooltipItem.parsed.y, 1);
                return unitOptic === '₽' ? TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн' : TooltipItem.dataset.label + ': ' + changedTooltipItem + ` ${unitOptic}`;
              },
            },
          },
          label: array[Object.keys(array)[1]],
          backgroundColor: array[Object.keys(array)[2]],
          data: array[Object.keys(array)[0]],
        })),
      ],
    })
  );
  dispatch(
    setDonutData({
      labels: donutMedia,

      datasets: [
        {
          label: 'My First Dataset',
          data: donutBudget,
          backgroundColor: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
          hoverOffset: 4,
        },
      ],
    })
  );
  isUpdatedDataFromAllCharts &&
    dispatch(
      set_investment_growth_graph_data({
        labels: labelsArr.map(item => `${round(setMillion(item))} млн`),
        datasets: [
          {
            type: 'scatter',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  return TooltipItem.dataset.label + ` ${updatedInputData.frequency}: ` + round(TooltipItem.parsed.y, 1) + ` %`;
                },
              },
            },
            label: 'Охват',
            backgroundColor: 'black',
            fill: false,
            data: coveragesGrowth,
            yAxisID: 'ohvat',
            y2axis: true,
            datalabels: {
              display: false,
            },
          },
          ...mediaArrayGrowth.map(array => ({
            type: 'bar',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  const changedTooltipItem = unitGrowth === '₽' ? round(setMillion(TooltipItem.parsed.y), 1) : round(TooltipItem.parsed.y, 1);
                  return unitGrowth === '₽' ? TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн' : TooltipItem.dataset.label + ': ' + changedTooltipItem + ` ${unitGrowth}`;
                },
              },
            },
            label: array[Object.keys(array)[1]],
            backgroundColor: array[Object.keys(array)[2]],
            data: array[Object.keys(array)[0]],
          })),
        ],
      })
    );

  isUpdatedDataFromAllCharts &&
    dispatch(
      set_investment_coverage_graph_data({
        labels: labelsArrCoverage.map(item => `Охват ${round(item)} %`),
        datasets: mediaArrayCoverage.map(array => ({
          type: 'bar',
          label: array[Object.keys(array)[1]],
          backgroundColor: array[Object.keys(array)[2]],
          data: array[Object.keys(array)[0]],
        })),
      })
    );

  dispatch(setStatus('success'));
};

export const changePieChart = (unit, grouping) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const chartsData = getState().charts.chartsData;
  const isChartsUpload = getState().charts.isChartsUpload;
  const dataFromXL = getState().charts.dataFromXL;
  const colorsArray = getState().charts.colorsArray;
  const colorsGroupArray = getState().charts.colorsGroupArray;

  const getDataForPieChart_DataFromXL = dataFromXL => {
    const donutData = dataFromXL.filter(item => item.Media1);
    const donutMedia_DataFromXL = donutData.map(item => item.Media1);
    const donutBudget_DataFromXL = donutData.map(item => item.Budget);
    const donutBudgetPr_DataFromXL = donutData.map(item => item.BudgetPr);
    const donutMediaGroup_DataFromXL = makeArrayGroupDataFromXL(donutData);
    const donutBudgetGroup_DataFromXL = makeArrayBudgetGroupDataFromXL(donutData);
    const donutBudgetPercentGroup_DataFromXL = makeArrayBudgetGroupPrDataFromXL(donutData);

    const donutAllColorsArray_DataFromXL = donutMedia_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });

    const donutAllGroupColorsArray_DataFromXL = donutMediaGroup_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    return [donutMedia_DataFromXL, donutBudget_DataFromXL, donutBudgetPr_DataFromXL, donutMediaGroup_DataFromXL, donutBudgetGroup_DataFromXL, donutBudgetPercentGroup_DataFromXL, donutAllColorsArray_DataFromXL, donutAllGroupColorsArray_DataFromXL];
  };
  const donutMedia_DataFromXL = isChartsUpload && getDataForPieChart_DataFromXL(dataFromXL)[0];
  const donutBudget_DataFromXL = isChartsUpload && getDataForPieChart_DataFromXL(dataFromXL)[1];
  const donutBudgetPr_DataFromXL = isChartsUpload && getDataForPieChart_DataFromXL(dataFromXL)[2];
  const donutMediaGroup_DataFromXL = isChartsUpload && getDataForPieChart_DataFromXL(dataFromXL)[3];
  const donutBudgetGroup_DataFromXL = isChartsUpload && getDataForPieChart_DataFromXL(dataFromXL)[4];
  const donutBudgetPercentGroup_DataFromXL = isChartsUpload && getDataForPieChart_DataFromXL(dataFromXL)[5];
  const donutAllColorsArray_DataFromXL = isChartsUpload && getDataForPieChart_DataFromXL(dataFromXL)[6];
  const donutAllGroupColorsArray_DataFromXL = isChartsUpload && getDataForPieChart_DataFromXL(dataFromXL)[7];

  const getDataForPieChart_DataFromChartsData = chartsData => {
    const BUDGET = JSON.parse(chartsData.data[0].Budget);
    const donutMedia = Object.keys(BUDGET);
    const donutBudget = makeArrayBudget(BUDGET);
    const donutBudgetPercent = makeArrayBudgetPr(BUDGET);
    const donutMediaGroup = makeArrayGroup(BUDGET);
    const donutBudgetGroup = makeArrayBudgetGroup(BUDGET);
    const donutBudgetPercentGroup = makeArrayBudgetGroupPr(BUDGET);
    const donutAllColorsArray = donutMedia.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });
    const donutAllGroupColorsArray = donutMediaGroup.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    return [donutMedia, donutBudget, donutBudgetPercent, donutMediaGroup, donutBudgetGroup, donutBudgetPercentGroup, donutAllColorsArray, donutAllGroupColorsArray];
  };

  const donutMedia = !isChartsUpload && getDataForPieChart_DataFromChartsData(chartsData)[0];
  const donutBudget = !isChartsUpload && getDataForPieChart_DataFromChartsData(chartsData)[1];
  const donutBudgetPercent = !isChartsUpload && getDataForPieChart_DataFromChartsData(chartsData)[2];
  const donutMediaGroup = !isChartsUpload && getDataForPieChart_DataFromChartsData(chartsData)[3];
  const donutBudgetGroup = !isChartsUpload && getDataForPieChart_DataFromChartsData(chartsData)[4];
  const donutBudgetPercentGroup = !isChartsUpload && getDataForPieChart_DataFromChartsData(chartsData)[5];
  const donutAllColorsArray = !isChartsUpload && getDataForPieChart_DataFromChartsData(chartsData)[6];
  const donutAllGroupColorsArray = !isChartsUpload && getDataForPieChart_DataFromChartsData(chartsData)[7];

  const chooseData = () => {
    if (isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return donutBudgetPr_DataFromXL;
        else if (unit === '₽') return donutBudget_DataFromXL;
      } else if (grouping === 'group') {
        if (unit === '%') return donutBudgetPercentGroup_DataFromXL;
        else if (unit === '₽') return donutBudgetGroup_DataFromXL;
      }
    } else if (!isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return donutBudgetPercent;
        else if (unit === '₽') return donutBudget;
      } else if (grouping === 'group') {
        if (unit === '%') return donutBudgetPercentGroup;
        else if (unit === '₽') return donutBudgetGroup;
      }
    }
  };

  const chooseLabel = () => {
    if (grouping === 'all') {
      if (isChartsUpload) return donutMedia_DataFromXL;
      else if (!isChartsUpload) return donutMedia;
    } else if (grouping === 'group') {
      if (isChartsUpload) return donutMediaGroup_DataFromXL;
      else if (!isChartsUpload) return donutMediaGroup;
    }
  };

  const chooseColors = () => {
    if (grouping === 'all') {
      if (isChartsUpload) return donutAllColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]);
      else if (!isChartsUpload) return donutAllColorsArray.map(i => Object.values(Object.values(i))[0]);
    } else if (grouping === 'group') {
      if (isChartsUpload) return donutAllGroupColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]);
      else if (!isChartsUpload) return donutAllGroupColorsArray.map(i => Object.values(Object.values(i))[0]);
    }
  };
  dispatch(
    setDonutData({
      labels: chooseLabel(),

      datasets: [
        {
          label: grouping === 'all' ? 'Pie' : 'PieGroup',
          data: chooseData(),
          backgroundColor: chooseColors(),
          hoverOffset: 4,
        },
      ],
    })
  );
  dispatch(setStatus('success'));
};

export const changeContributionChart = (unit, grouping, mainMedia) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const chartsData = getState().charts.chartsData;
  const dataFromXL = getState().charts.dataFromXL;
  const isChartsUpload = getState().charts.isChartsUpload;
  const colorsArray = getState().charts.colorsArray;
  const colorsGroupArray = getState().charts.colorsGroupArray;

  const getDataForContrChart_DataFromXL = dataFromXL => {
    const contributionData = dataFromXL.filter(item => item.Media2).filter(item => item.MainMedia === mainMedia);

    contributionData.sort(function (x, y) {
      return x.Media2 === x.MainMedia ? -1 : y.Media2 === y.MainMedia ? 1 : 0;
    });

    const contributionMedia_DataFromXL = contributionData.map(item => item.Media2);
    const contributionBudget_DataFromXL = contributionData.map(item => item.Budget);
    const contributionBudgetPercent_DataFromXL = contributionData.map(item => item.BudgetPr);
    const contributionMediaGroup_DataFromXL = makeArrayGroupDataFromXL(contributionData);
    const contributionBudgetGroup_DataFromXL = makeArrayBudgetGroupDataFromXL(contributionData);
    const contributionBudgetPercentGroup_DataFromXL = makeArrayBudgetGroupPrDataFromXL(contributionData);
    const contributionAllColorsArray_DataFromXL = contributionMedia_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });

    const contributionAllGroupColorsArray_DataFromXL = contributionMediaGroup_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    return [contributionMedia_DataFromXL, contributionBudget_DataFromXL, contributionBudgetPercent_DataFromXL, contributionMediaGroup_DataFromXL, contributionBudgetGroup_DataFromXL, contributionBudgetPercentGroup_DataFromXL, contributionAllColorsArray_DataFromXL, contributionAllGroupColorsArray_DataFromXL];
  };
  const contributionMedia_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[0];
  const contributionBudget_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[1];
  const contributionBudgetPercent_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[2];
  const contributionMediaGroup_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[3];
  const contributionBudgetGroup_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[4];
  const contributionBudgetPercentGroup_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[5];
  const contributionAllColorsArray_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[6];
  const contributionAllGroupColorsArray_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[7];

  const getDataForContrChart_DataFromChartsData = chartsData => {
    const INCREACH = JSON.parse(chartsData.data[0].Incremental_reach[mainMedia]);
    const contributionMedia = Object.keys(INCREACH);
    const contributionBudget = makeArrayBudget(INCREACH);
    const contributionBudgetPercent = makeArrayBudgetPr(INCREACH);
    const contributionMediaGroup = makeArrayGroup(INCREACH);
    const contributionBudgetGroup = makeArrayBudgetGroup(INCREACH);
    const contributionBudgetPercentGroup = makeArrayBudgetGroupPr(INCREACH);
    const contributionAllColorsArray = contributionMedia.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });

    const contributionAllGroupColorsArray = contributionMediaGroup.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    return [contributionMedia, contributionBudget, contributionBudgetPercent, contributionMediaGroup, contributionBudgetGroup, contributionBudgetPercentGroup, contributionAllColorsArray, contributionAllGroupColorsArray];
  };
  const contributionMedia = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[0];
  const contributionBudget = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[1];
  const contributionBudgetPercent = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[2];
  const contributionMediaGroup = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[3];
  const contributionBudgetGroup = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[4];
  const contributionBudgetPercentGroup = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[5];
  const contributionAllColorsArray = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[6];
  const contributionAllGroupColorsArray = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[7];

  const chooseData = () => {
    if (isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return contributionBudgetPercent_DataFromXL;
        else if (unit === '₽') return contributionBudget_DataFromXL;
      } else if (grouping === 'group') {
        if (unit === '%') return contributionBudgetPercentGroup_DataFromXL;
        else if (unit === '₽') return contributionBudgetGroup_DataFromXL;
      }
    } else if (!isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return contributionBudgetPercent;
        else if (unit === '₽') return contributionBudget;
      } else if (grouping === 'group') {
        if (unit === '%') return contributionBudgetPercentGroup;
        else if (unit === '₽') return contributionBudgetGroup;
      }
    }
  };

  const chooseLabel = () => {
    if (grouping === 'all') {
      if (isChartsUpload) return contributionMedia_DataFromXL;
      else if (!isChartsUpload) return contributionMedia;
    } else if (grouping === 'group') {
      if (isChartsUpload) return contributionMediaGroup_DataFromXL;
      else if (!isChartsUpload) return contributionMediaGroup;
    }
  };
  const chooseColors = () => {
    if (grouping === 'all') {
      if (isChartsUpload) return contributionAllColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]);
      else if (!isChartsUpload) return contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]);
    } else if (grouping === 'group') {
      if (isChartsUpload) return contributionAllGroupColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]);
      else if (!isChartsUpload) return contributionAllGroupColorsArray.map(i => Object.values(Object.values(i))[0]);
    }
  };
  dispatch(setMainMedia(mainMedia));
  dispatch(
    setContributionOfEachMedia_data({
      labels: chooseLabel(),

      datasets: [
        {
          label: grouping === 'all' ? 'Contribution' : 'ContributionGroup',
          data: chooseData(),
          backgroundColor: chooseColors(),
          hoverOffset: 4,
        },
      ],
    })
  );
  dispatch(setStatus('success'));
};

export const changeOpticChart = (unit, grouping) => async (dispatch, getState) => {
  const frequency = getState().input.frequency;
  const dataFromXL = getState().charts.dataFromXL;
  const isChartsUpload = getState().charts.isChartsUpload;
  const unitOptic = getState().charts.unitOptic;
  const colorsArray = getState().charts.colorsArray;
  const colorsGroupArray = getState().charts.colorsGroupArray;

  dispatch(setStatus('loading'));

  const getDataForOpticChart_DataFromXL = dataFromXL => {
    const budget_DataFromXL = `${round(
      setMillion(
        +dataFromXL
          .filter(item => item.Media3)
          .map(item => item.Option5)
          .reduce(function (accumulator, currentValue) {
            return accumulator + +currentValue;
          }, 0)
      )
    )} млн`;

    let coveragesDataFromXL = [];
    const opticData = dataFromXL.filter(item => item.Media3);
    const opticMedia = opticData.map(item => item.Media3);
    const opticReach = dataFromXL.filter(item => item.Reach);
    const mediaGroupArrDataFromXL = makeArrayGroupDataFromXL(opticData);

    opticReach.map(item => {
      coveragesDataFromXL.push(item.Option1, item.Option2, item.Option3, item.Option4, item.Option5, item.Option6, item.Option7, item.Option8, item.Option9);
      return item;
    });

    const mediaArrayOpticDataFromXL = opticMedia.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayOpticPercentDataFromXL = opticMedia.map(key => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayOpticGroupDataFromXL = mediaGroupArrDataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    const mediaArrayOpticGroupPercentDataFromXL = mediaGroupArrDataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    for (let i = 0; i < opticMedia.length; i++) {
      const findNameMedia = findName(opticMedia[i], mediaArrayOpticDataFromXL);
      const findObj = opticData.find(obj => obj.Media3 === opticMedia[i]);
      Object.values(findNameMedia)[0].push(findObj.Option1, findObj.Option2, findObj.Option3, findObj.Option4, findObj.Option5, findObj.Option6, findObj.Option7, findObj.Option8, findObj.Option9);
    }
    for (let i = 0; i < opticMedia.length; i++) {
      const findNameMedia = findName(opticMedia[i], mediaArrayOpticPercentDataFromXL);
      const findObj = opticData.find(obj => obj.Media3 === opticMedia[i]);
      Object.values(findNameMedia)[0].push(findObj.Option1Pr, findObj.Option2Pr, findObj.Option3Pr, findObj.Option4Pr, findObj.Option5Pr, findObj.Option6Pr, findObj.Option7Pr, findObj.Option8Pr, findObj.Option9Pr);
    }

    for (let i = 0; i < mediaGroupArrDataFromXL.length; i++) {
      const findNameMedia = findName(mediaGroupArrDataFromXL[i], mediaArrayOpticGroupDataFromXL);
      const findObjArr = opticData.filter(obj => obj.Group === mediaGroupArrDataFromXL[i]);
      let sumOption1 = 0;
      let sumOption2 = 0;
      let sumOption3 = 0;
      let sumOption4 = 0;
      let sumOption5 = 0;
      let sumOption6 = 0;
      let sumOption7 = 0;
      let sumOption8 = 0;
      let sumOption9 = 0;
      for (let i = 0; i < findObjArr.length; i++) {
        sumOption1 += +findObjArr[i].Option1;
        sumOption2 += +findObjArr[i].Option2;
        sumOption3 += +findObjArr[i].Option3;
        sumOption4 += +findObjArr[i].Option4;
        sumOption5 += +findObjArr[i].Option5;
        sumOption6 += +findObjArr[i].Option6;
        sumOption7 += +findObjArr[i].Option7;
        sumOption8 += +findObjArr[i].Option8;
        sumOption9 += +findObjArr[i].Option9;
      }
      Object.values(findNameMedia)[0].push(+sumOption1, +sumOption2, +sumOption3, +sumOption4, +sumOption5, +sumOption6, +sumOption7, +sumOption8, +sumOption9);
    }
    for (let i = 0; i < mediaGroupArrDataFromXL.length; i++) {
      const findNameMedia = findName(mediaGroupArrDataFromXL[i], mediaArrayOpticGroupPercentDataFromXL);
      const findObjArr = opticData.filter(obj => obj.Group === mediaGroupArrDataFromXL[i]);
      let sumOption1Pr = 0;
      let sumOption2Pr = 0;
      let sumOption3Pr = 0;
      let sumOption4Pr = 0;
      let sumOption5Pr = 0;
      let sumOption6Pr = 0;
      let sumOption7Pr = 0;
      let sumOption8Pr = 0;
      let sumOption9Pr = 0;
      for (let i = 0; i < findObjArr.length; i++) {
        sumOption1Pr += +findObjArr[i].Option1Pr;
        sumOption2Pr += +findObjArr[i].Option2Pr;
        sumOption3Pr += +findObjArr[i].Option3Pr;
        sumOption4Pr += +findObjArr[i].Option4Pr;
        sumOption5Pr += +findObjArr[i].Option5Pr;
        sumOption6Pr += +findObjArr[i].Option6Pr;
        sumOption7Pr += +findObjArr[i].Option7Pr;
        sumOption8Pr += +findObjArr[i].Option8Pr;
        sumOption9Pr += +findObjArr[i].Option9Pr;
      }
      Object.values(findNameMedia)[0].push(+sumOption1Pr, +sumOption2Pr, +sumOption3Pr, +sumOption4Pr, +sumOption5Pr, +sumOption6Pr, +sumOption7Pr, +sumOption8Pr, +sumOption9Pr);
    }
    return [coveragesDataFromXL, mediaArrayOpticDataFromXL, mediaArrayOpticPercentDataFromXL, mediaArrayOpticGroupDataFromXL, mediaArrayOpticGroupPercentDataFromXL, budget_DataFromXL];
  };

  const coveragesDataFromXL = isChartsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[0];
  const mediaArrayOpticDataFromXL = isChartsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[1];
  const mediaArrayOpticPercentDataFromXL = isChartsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[2];
  const mediaArrayOpticGroupDataFromXL = isChartsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[3];
  const mediaArrayOpticGroupPercentDataFromXL = isChartsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[4];
  const budget_DataFromXL = isChartsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[5];

  const chartsData = getState().charts.chartsData;
  const getDataForOpticChart_DataFromChartsData = chartsData => {
    let coverages = [];
    const MEDIA_MIX_COMPARE = chartsData.data[0].Media_mix_compare;
    const budget = `${round(setMillion(makeSumBudget(JSON.parse(MEDIA_MIX_COMPARE[4][1]))))} млн`;
    const obg = JSON.parse(MEDIA_MIX_COMPARE[1][1]);
    const mediaGroupArr = makeArrayGroup(obg);
    const keys = Object.keys(obg);

    const mediaArrayOptic = keys.map(key => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayOpticPercent = keys.map(key => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayOpticGroup = mediaGroupArr.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    const mediaArrayOpticGroupPercent = mediaGroupArr.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    for (const data of chartsData.data[0].Media_mix_compare) {
      const DATA = JSON.parse(data[1]);
      const sum = findSum(DATA);
      coverages.push(data[0]);
      const opticGroupBudgetArr = makeArrayBudgetGroupBar(DATA);
      for (let i = 0; i < keys.length; i++) {
        const findNameMedia = findName(keys[i], mediaArrayOptic);
        Object.values(findNameMedia)[0].push(DATA[keys[i]].Budget);
      }
      for (let i = 0; i < keys.length; i++) {
        const findNameMedia = findName(keys[i], mediaArrayOpticPercent);
        Object.values(findNameMedia)[0].push(+((+DATA[keys[i]].Budget * 100) / sum).toFixed(1));
      }
      for (let i = 0; i < mediaGroupArr.length; i++) {
        const findNameMedia = findName(mediaGroupArr[i], mediaArrayOpticGroup);
        const findBudgetMedia = findName(mediaGroupArr[i], opticGroupBudgetArr);
        findBudgetMedia && Object.values(findNameMedia)[0].push(Object.values(findBudgetMedia)[0][0]);
      }
      for (let i = 0; i < mediaGroupArr.length; i++) {
        const findNameMedia = findName(mediaGroupArr[i], mediaArrayOpticGroupPercent);
        const findBudgetMedia = findName(mediaGroupArr[i], opticGroupBudgetArr);
        findBudgetMedia && Object.values(findNameMedia)[0].push(+((+Object.values(findBudgetMedia)[0][0] * 100) / sum).toFixed(1));
      }
    }
    return [coverages, mediaArrayOptic, mediaArrayOpticPercent, mediaArrayOpticGroup, mediaArrayOpticGroupPercent, budget];
  };

  const coverages = !isChartsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[0];
  const mediaArrayOptic = !isChartsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[1];
  const mediaArrayOpticPercent = !isChartsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[2];
  const mediaArrayOpticGroup = !isChartsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[3];
  const mediaArrayOpticGroupPercent = !isChartsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[4];
  const budget = !isChartsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[5];
  const budgetFinal = !isChartsUpload ? budget : budget_DataFromXL;

  const chooseData = () => {
    if (isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return mediaArrayOpticPercentDataFromXL;
        else if (unit === '₽') return mediaArrayOpticDataFromXL;
      } else if (grouping === 'group') {
        if (unit === '%') return mediaArrayOpticGroupPercentDataFromXL;
        else if (unit === '₽') return mediaArrayOpticGroupDataFromXL;
      }
    } else if (!isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return mediaArrayOpticPercent;
        else if (unit === '₽') return mediaArrayOptic;
      } else if (grouping === 'group') {
        if (unit === '%') return mediaArrayOpticGroupPercent;
        else if (unit === '₽') return mediaArrayOpticGroup;
      }
    }
  };

  dispatch(
    setOpticMediaMixGraphData({
      labels: [budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal],
      datasets: [
        {
          type: 'line',
          tooltip: {
            callbacks: {
              label: function (TooltipItem) {
                return TooltipItem.dataset.label + ` ${frequency}: ` + round(TooltipItem.parsed.y, 2) + ` %`;
              },
            },
          },
          label: 'Охват',
          borderColor: 'black',
          backgroundColor: 'black',
          borderWidth: 2,
          fill: false,
          data: isChartsUpload ? coveragesDataFromXL : coverages,
          yAxisID: 'ohvat',
          y2axis: true,
          datalabels: {
            display: false,
          },
        },
        ...chooseData().map(array => ({
          type: 'bar',
          tooltip: {
            callbacks: {
              label: function (TooltipItem) {
                const changedTooltipItem = unitOptic === '₽' ? round(setMillion(TooltipItem.parsed.y), 1) : round(TooltipItem.parsed.y, 1);
                return unitOptic === '₽' ? TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн' : TooltipItem.dataset.label + ': ' + changedTooltipItem + ` ${unitOptic}`;
              },
            },
          },
          label: array[Object.keys(array)[1]],
          backgroundColor: array[Object.keys(array)[2]],
          data: array[Object.keys(array)[0]],
        })),
      ],
    })
  );
  dispatch(setStatus('success'));
};
