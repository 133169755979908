import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step1Page.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { TargetAudience } from '../../components/InputParameters/Audience/TargetAudience/TargetAudience';
import { Geo } from '../../components/InputParameters/Audience/Geo/Geo';
import { Period } from '../../components/InputParameters/Settings/Period/Period';
import { CampaignType } from '../../components/InputParameters/Settings/СampaignType/CampaignType';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { Header } from '../../../ui/components/Header/Header';
import { Checkbox } from '../../components/Common/Checkbox/Checkbox';
import { setIsRestrictions, setInputDataChanged } from '../../../bll/inputParameters_reducer';
import { CampaignName } from '../../components/InputParameters/CampaignName/CampaignName';

export const Step1Page = props => {
  const { targetAudienceData } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const onClickNextButton = () => {
    history.push('/step2');
  };
  const onClickBackButton = () => {
    history.push('/');
  };
  const onChangeIsRestrictions = e => {
    e.target.checked ? dispatch(setIsRestrictions(1)) : dispatch(setIsRestrictions(0));
    dispatch(setInputDataChanged(true));
  };

  const [targetAudienceIsSelected, setTargetAudienceIsSelected] = useState(false);
  const [geoIsSelected, setGeoIsSelected] = useState(false);
  const [periodIsSelected, setPeriodIsSelected] = useState(false);
  const [campaignTypeIsSelected, setCampaignTypeIsSelected] = useState(false);
  const isRestrictions = useSelector(state => state.input.isRestrictions);

  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <>
      <Header />
      <div className={s.step1Wrapper}>
        <StepButton onClick={onClickBackButton} title={'Вернуться назад'} />
        <div className={s.inputParametersBlock}>
          <h1>Вводные параметры для флайта</h1>

          <CampaignName />

          <div className={s.audienceBlock}>
            <div className={s.iconWrapper}>
              <FontAwesomeIcon icon={faCog} size='3x' className={s.icon} />
            </div>
            <div className={s.audienceBlockContent}>
              <TargetAudience setTargetAudienceIsSelected={setTargetAudienceIsSelected} targetAudienceData={targetAudienceData} />
              <Geo setGeoIsSelected={setGeoIsSelected} />
              <Period setPeriodIsSelected={setPeriodIsSelected} />
              <CampaignType setCampaignTypeIsSelected={setCampaignTypeIsSelected} />
            </div>
          </div>
          <Checkbox
            onChangeChecked={e => {
              onChangeIsRestrictions(e);
            }}
            checked={isRestrictions === 1 ? true : false}
            label={'Учитывать ограничения емкости инвентаря каждого\n медиаканала для выбранного количества дней?'}
            id={'IsRestrictions'}
          />
        </div>
        <StepButton onClick={onClickNextButton} title={'Следующий шаг'} isDisabled={targetAudienceIsSelected & geoIsSelected & periodIsSelected & campaignTypeIsSelected ? false : true} />
      </div>
    </>
  );
};
