import Axios from 'axios';

// export const API2 = {
//   getIp() {
//     return Axios.get('https://mediamix.mediainstinctgroup.ru/get_ip/');
//   },
//   get_media(payload) {
//     return Axios.post(`https://mediamix.mediainstinctgroup.ru/startdata_new`, payload);
//   },
//   post_chartDataPath1_budget(payload) {
//     return Axios.post(`https://mediamix.mediainstinctgroup.ru/get_opt`, payload);
//   },
//   post_chartDataPath1_coverage(payload) {
//     return Axios.post(`https://mediamix.mediainstinctgroup.ru/get_opt_reach`, payload);
//   },
//   post_chartDataPath2_growthGraph(payload) {
//     return Axios.post(`https://mediamix.mediainstinctgroup.ru/get_opt_parts`, payload);
//   },
//   post_chartDataPath2_coverageGraph(payload) {
//     return Axios.post(`https://mediamix.mediainstinctgroup.ru/get_opt_reach_parts`, payload);
//   },
// };

export const API2 = {
  getIp() {
    return Axios.get('/get_ip/');
  },
  get_media(payload) {
    return Axios.post(`/startdata_new`, payload);
  },
  post_chartDataPath1_budget(payload) {
    return Axios.post(`/get_opt`, payload);
  },
  post_chartDataPath1_coverage(payload) {
    return Axios.post(`/get_opt_reach`, payload);
  },
  post_chartDataPath2_growthGraph(payload) {
    return Axios.post(`/get_opt_parts`, payload);
  },
  post_chartDataPath2_coverageGraph(payload) {
    return Axios.post(`/get_opt_reach_parts`, payload);
  },
};