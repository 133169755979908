import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './CampaignName.module.css';
import { Select } from '../../../components/Common/Select/Select';
import { setCampaignName, setPeriod, setCampaignType, setTargetAudience, setGeo, setMediaIsSelected, setInputDataChanged, setMonth, setIsMonthVisible } from '../../../../bll/inputParameters_reducer';
import { setMainMedia, setIsChartsUpload } from '../../../../bll/charts_reducer';

export const CampaignName = React.memo(props => {

  const dispatch = useDispatch();
  const campaignName = useSelector(state => state.input.campaignName);
  const campaignType = useSelector(state => state.input.campaignType);
  const geo = useSelector(state => state.input.geo);

  const onChangeCampaignName = selectItem => {
    dispatch(setCampaignName(selectItem));
    if(selectItem === 'Имидж Платежные Инструменты') {
      dispatch(setTargetAudience('All 18+'))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Имидж' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if (selectItem === 'Мир Молодежь') {
      dispatch(setTargetAudience('All 18-24'))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Промо' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if(selectItem === 'Мир Акция') {
      dispatch(setTargetAudience('All 25-55'))
      dispatch(setPeriod(28))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Промо' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if (selectItem === 'Мир BTS') {
      dispatch(setTargetAudience('All 25-55'))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Промо' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if (selectItem === 'Мир Имидж'){
      dispatch(setTargetAudience("All 25-45"))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Имидж' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if (selectItem === 'Мир Имидж НГ') {
      dispatch(setTargetAudience('All 18+'))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Имидж' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if (selectItem === 'СБП 1') {
      dispatch(setTargetAudience('All 18-54'))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Промо' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if (selectItem === 'СБП 2') {
      dispatch(setTargetAudience('All 18-35'))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Промо' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if (selectItem === 'СБП 3') {
      dispatch(setTargetAudience('All 18-54'))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Промо' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if (selectItem === 'СБП 4') {
      dispatch(setTargetAudience('All 18-54'))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Промо' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    } else if (selectItem === 'СБП 5') {
      dispatch(setTargetAudience('All 25-45'))
      dispatch(setPeriod(35))
      const updatedCTState = campaignType.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'Промо' ? selectedItem : otherItem;
      });
      dispatch(setCampaignType(updatedCTState));
      const updatedGeoState = geo.map(item => {
        const selectedItem = { ...item };
        selectedItem.isChecked = true;
        const otherItem = { ...item };
        otherItem.isChecked = false;
        return item.name === 'NAT' ? selectedItem : otherItem;
      });
      dispatch(setGeo(updatedGeoState));
    }
   
    dispatch(setMediaIsSelected(false));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
    dispatch(setMonth('Июнь'));
    dispatch(setIsMonthVisible(1));
  };

  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        НАЗВАНИЕ КАМПАНИИ
      </div>
      <div className={s.blockContent}>
        <Select name={'campaignName'} value={campaignName} onChange={onChangeCampaignName} items={['Имидж Платежные Инструменты', 'Мир Молодежь', 'Мир Акция', 'Мир BTS', 'Мир Имидж', 'Мир Имидж НГ', 'СБП 1', 'СБП 2', 'СБП 3', 'СБП 4', 'СБП 5']} location={'campaignName'} />
      </div>
    </div>
  );
});
