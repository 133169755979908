import React from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-export-excel';
import { StartButton } from '../Common/StartButton/StartButton';
import save from '../../../assets/images/saveNew.png';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const SaveXLSFlights = props => {
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const flightsPostData = useSelector(state => state.flights.flightsPostData);
  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);
  const dataFromXL_Flights = useSelector(state => state.flights_charts.dataFromXL_Flights);
  const flightsUpload = useSelector(state => state.flights_charts.flightsUpload);
  const CPPAllMonth = useSelector(state => state.flights.CPPAllMonth);
  const copyCPPAllMonth = useSelector(state => state.flights.copyCPPAllMonth);

  const getDataForExportXLSPart1_DataFromXL = () => {
    const dataSetDonut_dataFromXL = [];
    const DATASETCONTR_dataFromXL = [];
    const dataSetOptic_dataFromXL = [];
    const dataSetReachOptic_dataFromXL = [];
    const dataset_Media_group_reach_DataFromXL = [];
    const dataset_Media_reach_DataFromXL = [];
    const dataset_Total_reach_DataFromXL = [];
    const dataset_Total_cprp_DataFromXL = [];

    const dataSetTA_dataFromXL = flightsPostData;
    const dataSetMedia_dataFromXL = [];
    const dataSetGroupCheckedArr_dataFromXL = [];
    const datasetMediaDefault_dataFromXL = [];
    const datasetCopyStartMedia_dataFromXL = [];

    const datasetCPPAllMonth_dataFromXL = CPPAllMonth.map(item=> {
      return {
        CPPOrigin: item.CPP,
        campaignType: item.campaignType,
        coeff: item.coeff,
        coeffImage: item.coeffImage,
        coeffPromo: item.coeffPromo,
        group: item.group,
        isChecked: item.isChecked,
        k: item.k,
        media: item.media,
        Август: item.Август,
        Апрель: item.Апрель,
        Декабрь: item.Декабрь,
        Июль: item.Июль,
        Июнь: item.Июнь,
        Май: item.Май,
        Март: item.Март,
        Ноябрь: item.Ноябрь,
        Октябрь: item.Октябрь,
        Сентябрь: item.Сентябрь,
        Февраль: item.Февраль,
        Январь: item.Январь,
      }
    })
    const datasetCopyCPPAllMonth_dataFromXL = copyCPPAllMonth.map(item=> {
      return {
        CPPCopy: item.CPP,
        campaignType: item.campaignType,
        coeff: item.coeff,
        coeffImage: item.coeffImage,
        coeffPromo: item.coeffPromo,
        group: item.group,
        isChecked: item.isChecked,
        k: item.k,
        media: item.media,
        Август: item.Август,
        Апрель: item.Апрель,
        Декабрь: item.Декабрь,
        Июль: item.Июль,
        Июнь: item.Июнь,
        Май: item.Май,
        Март: item.Март,
        Ноябрь: item.Ноябрь,
        Октябрь: item.Октябрь,
        Сентябрь: item.Сентябрь,
        Февраль: item.Февраль,
        Январь: item.Январь,
      }
    })

    flightsPostData.map(item => {
      dataFromXL_Flights
        .filter(item => item.Media1)
        .filter(i => i.flight === String(item.flight))
        .map(item => {
          dataSetDonut_dataFromXL.push({
            flight: item.flight,
            name: item.Media1,
            budget: item.Budget,
            budgetPr: item.BudgetPr,
            group: item.Group,
          });
          return item;
        });

      const contributionData = dataFromXL_Flights.filter(item => item.Media2).filter(i => i.flight === String(item.flight));

      const makeArrayMediaContributionName = arr => {
        let newArr = [];
        arr.map(item => {
          !newArr.find(arrItem => arrItem === item.MainMedia) && newArr.push(item.MainMedia);
          return item;
        });
        return newArr;
      };
      const mediaContribution = makeArrayMediaContributionName(contributionData);

      for (var i = 0; i < mediaContribution.length; i++) {
        const arr = contributionData
          // eslint-disable-next-line no-loop-func
          .filter(item => item.MainMedia === mediaContribution[i])
          .map(item => ({
            flight: item.flight,
            flightNumber: dataSetTA_dataFromXL.find(d => d.flight === item.flight) && dataSetTA_dataFromXL.find(d => d.flight === item.flight).flightNumber,
            mainMedia: item.MainMedia,
            name: item.Media2,
            group: item.Group,
            budget: item.Budget,
            budgetPr: item.BudgetPr,
          }));
        DATASETCONTR_dataFromXL.push(arr);
      }

      dataFromXL_Flights
        .filter(item => item.Media3)
        .filter(i => i.flight === String(item.flight))
        .map(item => {
          dataSetOptic_dataFromXL.push({
            flight: item.flight,
            name: item.Media3,
            group: item.Group,
            option1: item.Option1,
            option1Pr: item.Option1Pr,
            option2: item.Option2,
            option2Pr: item.Option2Pr,
            option3: item.Option3,
            option3Pr: item.Option3Pr,
            option4: item.Option4,
            option4Pr: item.Option4Pr,
            option5: item.Option5,
            option5Pr: item.Option5Pr,
            option6: item.Option6,
            option6Pr: item.Option6Pr,
            option7: item.Option7,
            option7Pr: item.Option7Pr,
            option8: item.Option8,
            option8Pr: item.Option8Pr,
            option9: item.Option9,
            option9Pr: item.Option9Pr,
          });
          return item;
        });

      dataFromXL_Flights
        .filter(item => item.Reach)
        .filter(i => i.flight === String(item.flight))
        .map(item => {
          dataSetReachOptic_dataFromXL.push({
            flight: item.flight,
            name: item.Reach,
            option1: item.Option1,
            option2: item.Option2,
            option3: item.Option3,
            option4: item.Option4,
            option5: item.Option5,
            option6: item.Option6,
            option7: item.Option7,
            option8: item.Option8,
            option9: item.Option9,
          });
          return item;
        });

      dataset_Media_group_reach_DataFromXL.push(dataFromXL_Flights.filter(item => item.media_group_reach).filter(i => i.flight === String(item.flight))[0]);
      dataset_Media_reach_DataFromXL.push(dataFromXL_Flights.filter(item => item.media_reach).filter(i => i.flight === String(item.flight))[0]);
      dataset_Total_reach_DataFromXL.push(dataFromXL_Flights.filter(item => item.Total_reach).filter(i => i.flight === String(item.flight))[0]);
      dataset_Total_cprp_DataFromXL.push(dataFromXL_Flights.filter(item => item.Total_cprp).filter(i => i.flight === String(item.flight))[0]);

      return {
        item,
      };
    });

    flightsMediaArr.map(item => {
      item.groupCheckedArr.map(f => {
        dataSetGroupCheckedArr_dataFromXL.push({
          flight: item.flight,
          name: Object.keys(f)[0],
          isChecked: Object.values(f)[0],
        });
        return f;
      });

      item.media.map(i => {
        dataSetMedia_dataFromXL.push({
          flight: item.flight,
          CPP: i.CPP,
          campaignType: i.campaignType,
          coeff: i.coeff,
          finishBudget: i.finishBudget,
          group: i.group,
          isChecked: i.isChecked,
          k: i.k,
          media: i.media,
          startBudget: i.startBudget,
        });
        return i;
      });

      item.defaultMediaData.map(i => {
        datasetMediaDefault_dataFromXL.push({
          flight: item.flight,
          CPP: i.CPP,
          campaignType: i.campaignType,
          coeff: i.coeff,
          finishBudget: i.finishBudget,
          group: i.group,
          isChecked: i.isChecked,
          k: i.k,
          media: i.media,
          startBudget: i.startBudget,
        });
        return i;
      });
      item.copyStartMedia.map(i => {
        datasetCopyStartMedia_dataFromXL.push({
          flight: item.flight,
          CPP: i.CPP,
          campaignType: i.campaignType,
          coeff: i.coeff,
          finishBudget: i.finishBudget,
          group: i.group,
          isChecked: i.isChecked,
          k: i.k,
          media: i.media,
          startBudget: i.startBudget,
        });
        return i;
      });

      return {
        item,
      };
    });

    return [dataSetDonut_dataFromXL, DATASETCONTR_dataFromXL, dataSetOptic_dataFromXL, dataSetReachOptic_dataFromXL, dataSetTA_dataFromXL, dataSetMedia_dataFromXL, dataSetGroupCheckedArr_dataFromXL, dataset_Media_group_reach_DataFromXL, dataset_Media_reach_DataFromXL, dataset_Total_reach_DataFromXL, dataset_Total_cprp_DataFromXL, datasetMediaDefault_dataFromXL, datasetCopyStartMedia_dataFromXL, datasetCPPAllMonth_dataFromXL, datasetCopyCPPAllMonth_dataFromXL];
  };
  const dataSetDonut_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[0];
  const DATASETCONTR_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[1];
  const dataSetOptic_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[2];
  const dataSetReachOptic_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[3];
  const dataSetTA_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[4];
  const dataSetMedia_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[5];
  const dataSetGroupCheckedArr_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[6];
  const dataset_Media_group_reach_DataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[7];
  const dataset_Media_reach_DataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[8];
  const dataset_Total_reach_DataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[9];
  const dataset_Total_cprp_DataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[10];
  const datasetMediaDefault_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[11];
  const datasetCopyStartMedia_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[12];
  const  datasetCPPAllMonth_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[13];
  const datasetCopyCPPAllMonth_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[14];

  const getDataForExportXLSPart1_DataFromChartsData = () => {
    const dataSetDonut = [];
    const dataSetReachOptic = [];
    const dataSetOptic = [];
    const DATASETCONTR = [];
    const dataset_Media_group_reach = [];
    const dataset_Media_reach = [];
    const dataset_Total_reach = [];
    const dataset_Total_cprp = [];
    
    const dataSetTA = flightsPostData;
    const dataSetMedia = [];
    const dataSetGroupCheckedArr = [];
    const datasetMediaDefault = [];
    const datasetCopyStartMedia = [];

    const datasetCPPAllMonth = CPPAllMonth.map(item=> {
      return {
        CPPOrigin: item.CPP,
        campaignType: item.campaignType,
        coeff: item.coeff,
        coeffImage: item.coeffImage,
        coeffPromo: item.coeffPromo,
        group: item.group,
        isChecked: item.isChecked,
        k: item.k,
        media: item.media,
        Август: item.Август,
        Апрель: item.Апрель,
        Декабрь: item.Декабрь,
        Июль: item.Июль,
        Июнь: item.Июнь,
        Май: item.Май,
        Март: item.Март,
        Ноябрь: item.Ноябрь,
        Октябрь: item.Октябрь,
        Сентябрь: item.Сентябрь,
        Февраль: item.Февраль,
        Январь: item.Январь,
      }
    })
    const datasetCopyCPPAllMonth = copyCPPAllMonth.map(item=> {
      return {
        CPPCopy: item.CPP,
        campaignType: item.campaignType,
        coeff: item.coeff,
        coeffImage: item.coeffImage,
        coeffPromo: item.coeffPromo,
        group: item.group,
        isChecked: item.isChecked,
        k: item.k,
        media: item.media,
        Август: item.Август,
        Апрель: item.Апрель,
        Декабрь: item.Декабрь,
        Июль: item.Июль,
        Июнь: item.Июнь,
        Май: item.Май,
        Март: item.Март,
        Ноябрь: item.Ноябрь,
        Октябрь: item.Октябрь,
        Сентябрь: item.Сентябрь,
        Февраль: item.Февраль,
        Январь: item.Январь,
      }
    })

    flightsChartsData.map(item => {
      const mainMedia = item.mainMedia;
      const BUDGET = JSON.parse(item.chartsData.data[0].Budget);
      const donutMedia = Object.keys(BUDGET);

      const MEDIA_MIX_COMPARE = item.chartsData.data[0].Media_mix_compare;
      const obg = JSON.parse(MEDIA_MIX_COMPARE[1][1]);
      const opticMedia = Object.keys(obg);

      const INCREACH = JSON.parse(item.chartsData.data[0].Incremental_reach[mainMedia]);
      const contributionMedia = Object.keys(INCREACH);

      for (const key in item.chartsData.data[0].Incremental_reach) {
        const INCREACH_ITEM = JSON.parse(item.chartsData.data[0].Incremental_reach[key]);
        const dataSetContr = contributionMedia.map((d, i) => {
          return {
            flight: item.flight,
            flightNumber: dataSetTA.find(d => d.flight === item.flight) && dataSetTA.find(d => d.flight === item.flight).flightNumber,
            mainMedia: key,
            name: d,
            group: BUDGET[d].group,
            budget: INCREACH_ITEM[d].Budget,
            budgetPr: INCREACH_ITEM[d].BudgetPr,
          };
        });
        DATASETCONTR.push(dataSetContr);
      }

      donutMedia.map(i => {
        dataSetDonut.push({
          flight: item.flight,
          name: i,
          group: BUDGET[i].group,
          budget: BUDGET[i].Budget,
          budgetPr: BUDGET[i].BudgetPr,
        });
        return i;
      });

      opticMedia.map(b => {
        const findGroup = media => {
          const findObj = MEDIA_MIX_COMPARE.find(item => JSON.parse(item[1])[media].name === media);
          return JSON.parse(findObj[1])[media].group;
        };
        dataSetOptic.push({
          flight: item.flight,
          name: b,
          group: findGroup(b),
          option1: JSON.parse(MEDIA_MIX_COMPARE[0][1])[b].Budget,
          option2: JSON.parse(MEDIA_MIX_COMPARE[1][1])[b].Budget,
          option3: JSON.parse(MEDIA_MIX_COMPARE[2][1])[b].Budget,
          option4: JSON.parse(MEDIA_MIX_COMPARE[3][1])[b].Budget,
          option5: JSON.parse(MEDIA_MIX_COMPARE[4][1])[b].Budget,
          option6: JSON.parse(MEDIA_MIX_COMPARE[5][1])[b].Budget,
          option7: JSON.parse(MEDIA_MIX_COMPARE[6][1])[b].Budget,
          option8: JSON.parse(MEDIA_MIX_COMPARE[7][1])[b].Budget,
          option9: JSON.parse(MEDIA_MIX_COMPARE[8][1])[b].Budget,
          option1Pr: +(JSON.parse(MEDIA_MIX_COMPARE[0][1])[b].BudgetPr * 100).toFixed(1),
          option2Pr: +(JSON.parse(MEDIA_MIX_COMPARE[1][1])[b].BudgetPr * 100).toFixed(1),
          option3Pr: +(JSON.parse(MEDIA_MIX_COMPARE[2][1])[b].BudgetPr * 100).toFixed(1),
          option4Pr: +(JSON.parse(MEDIA_MIX_COMPARE[4][1])[b].BudgetPr * 100).toFixed(1),
          option5Pr: +(JSON.parse(MEDIA_MIX_COMPARE[3][1])[b].BudgetPr * 100).toFixed(1),
          option6Pr: +(JSON.parse(MEDIA_MIX_COMPARE[5][1])[b].BudgetPr * 100).toFixed(1),
          option7Pr: +(JSON.parse(MEDIA_MIX_COMPARE[6][1])[b].BudgetPr * 100).toFixed(1),
          option8Pr: +(JSON.parse(MEDIA_MIX_COMPARE[7][1])[b].BudgetPr * 100).toFixed(1),
          option9Pr: +(JSON.parse(MEDIA_MIX_COMPARE[8][1])[b].BudgetPr * 100).toFixed(1),
        });
        return b;
      });
      dataSetReachOptic.push({
        flight: item.flight,
        name: 'reach',
        option1: MEDIA_MIX_COMPARE[0][0],
        option2: MEDIA_MIX_COMPARE[1][0],
        option3: MEDIA_MIX_COMPARE[2][0],
        option4: MEDIA_MIX_COMPARE[3][0],
        option5: MEDIA_MIX_COMPARE[4][0],
        option6: MEDIA_MIX_COMPARE[5][0],
        option7: MEDIA_MIX_COMPARE[6][0],
        option8: MEDIA_MIX_COMPARE[7][0],
        option9: MEDIA_MIX_COMPARE[8][0],
      });

      const Media_group_reach = item.chartsData.data[0].Media_group_reach;
      const MediaGroupArr = Object.keys(Media_group_reach);
      MediaGroupArr.map(g => {
        dataset_Media_group_reach.push({
          flight: item.flight,
          name: 'Media_group_reach',
          media_group_reach: g,
          '1+': Media_group_reach[g][0],
          '2+': Media_group_reach[g][1],
          '3+': Media_group_reach[g][2],
          '4+': Media_group_reach[g][3],
          '5+': Media_group_reach[g][4],
          '6+': Media_group_reach[g][5],
          '7+': Media_group_reach[g][6],
          '8+': Media_group_reach[g][7],
          '9+': Media_group_reach[g][8],
          '10+': Media_group_reach[g][9],
        });
        return g;
      });
      const Media_reach = item.chartsData.data[0].Media_reach;
      const MediaArr = Object.keys(Media_reach);
      MediaArr.map(h => {
        dataset_Media_reach.push({
          flight: item.flight,
          name: 'Media_reach',
          media_reach: h,
          '1+': Media_reach[h][0],
          '2+': Media_reach[h][1],
          '3+': Media_reach[h][2],
          '4+': Media_reach[h][3],
          '5+': Media_reach[h][4],
          '6+': Media_reach[h][5],
          '7+': Media_reach[h][6],
          '8+': Media_reach[h][7],
          '9+': Media_reach[h][8],
          '10+': Media_reach[h][9],
        });
        return h;
      });

      const Total_reach_cprp = item.chartsData.data[0].Total_reach_cprp;

      dataset_Total_reach.push({
        flight: item.flight,
        Total_reach: 'Total_reach',
        '1+': Total_reach_cprp[0][0],
        '2+': Total_reach_cprp[0][1],
        '3+': Total_reach_cprp[0][2],
        '4+': Total_reach_cprp[0][3],
        '5+': Total_reach_cprp[0][4],
        '6+': Total_reach_cprp[0][5],
        '7+': Total_reach_cprp[0][6],
        '8+': Total_reach_cprp[0][7],
        '9+': Total_reach_cprp[0][8],
        '10+': Total_reach_cprp[0][9],
      });

      dataset_Total_cprp.push({
        flight: item.flight,
        Total_cprp: 'Total_cprp',
        '1+': Total_reach_cprp[1][0],
        '2+': Total_reach_cprp[1][1],
        '3+': Total_reach_cprp[1][2],
        '4+': Total_reach_cprp[1][3],
        '5+': Total_reach_cprp[1][4],
        '6+': Total_reach_cprp[1][5],
        '7+': Total_reach_cprp[1][6],
        '8+': Total_reach_cprp[1][7],
        '9+': Total_reach_cprp[1][8],
        '10+': Total_reach_cprp[1][9],
      });

      return {
        item,
      };
    });

    flightsMediaArr.map(item => {
      item.groupCheckedArr.map(f => {
        dataSetGroupCheckedArr.push({
          flight: item.flight,
          name: Object.keys(f)[0],
          isChecked: Object.values(f)[0],
        });
        return f;
      });

      item.media.map(i => {
        dataSetMedia.push({
          flight: item.flight,
          CPP: i.CPP,
          campaignType: i.campaignType,
          coeff: i.coeff,
          finishBudget: i.finishBudget,
          group: i.group,
          isChecked: i.isChecked,
          k: i.k,
          media: i.media,
          startBudget: i.startBudget,
        });
        return i;
      });

      item.defaultMediaData.map(i => {
        datasetMediaDefault.push({
          flight: item.flight,
          CPP: i.CPP,
          campaignType: i.campaignType,
          coeff: i.coeff,
          finishBudget: i.finishBudget,
          group: i.group,
          isChecked: i.isChecked,
          k: i.k,
          media: i.media,
          startBudget: i.startBudget,
        });
        return i;
      });

      item.copyStartMedia.map(i => {
        datasetCopyStartMedia.push({
          flight: item.flight,
          CPP: i.CPP,
          campaignType: i.campaignType,
          coeff: i.coeff,
          finishBudget: i.finishBudget,
          group: i.group,
          isChecked: i.isChecked,
          k: i.k,
          media: i.media,
          startBudget: i.startBudget,
        });
        return i;
      });

      return {
        item,
      };
    });

    return [dataSetDonut, DATASETCONTR, dataSetOptic, dataSetReachOptic, dataSetGroupCheckedArr, dataSetTA, dataSetMedia, dataset_Media_group_reach, dataset_Media_reach, dataset_Total_reach, dataset_Total_cprp, datasetMediaDefault, datasetCopyStartMedia, datasetCPPAllMonth, datasetCopyCPPAllMonth];
  };
  const dataSetDonut = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[0];
  const DATASETCONTR = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[1];
  const dataSetOptic = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[2];
  const dataSetReachOptic = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[3];
  const dataSetGroupCheckedArr = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[4];
  const dataSetTA = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[5];
  const dataSetMedia = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[6];
  const dataset_Media_group_reach = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[7];
  const dataset_Media_reach = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[8];
  const dataset_Total_reach = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[9];
  const dataset_Total_cprp = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[10];
  const datasetMediaDefault = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[11];
  const datasetCopyStartMedia = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[12];
  const  datasetCPPAllMonth = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[13];
  const datasetCopyCPPAllMonth = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[14];

  const chooseDATASETCONTR = () => {
    if (flightsUpload) {
      return DATASETCONTR_dataFromXL;
    } else if (!flightsUpload) {
      return DATASETCONTR;
    }
  };
  return (
    <div>
      <ExcelFile
        element={
          <StartButton view={'red'}>
            <img src={save} alt='save' style={{ width: '30px', height: '30px', marginRight: '5px' }} />

            <div>
              <div>Сохранить </div>
              <div> проект </div>
            </div>
          </StartButton>
        }
        filename='save_media_mix_calculator_flights'
      >
        <ExcelSheet data={flightsUpload ? dataSetTA_dataFromXL : dataSetTA} name='Вводные данные'>
          <ExcelColumn label='flightNumber' value='flightNumber' />
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='targetAudience' value='targetAudience' />
          <ExcelColumn label='geo' value='geo' />
          <ExcelColumn label='period' value='period' />
          <ExcelColumn label='flightsIsRestrictions' value='flightsIsRestrictions' />
          <ExcelColumn label='startDate' value='startDate' />
          <ExcelColumn label='finishDate' value='finishDate' />
          <ExcelColumn label='campaignType' value='campaignType' />
          <ExcelColumn label='frequency' value='frequency' />
          <ExcelColumn label='budget_coverageNAME' value='budget_coverageNAME' />
          <ExcelColumn label='budget_coverage' value='budget_coverage' />
          <ExcelColumn label='Aud_totalCurrentValue' value='Aud_totalCurrentValue' />
          <ExcelColumn label='month' value='month' />
          <ExcelColumn label='knowledgeGeneral' value='knowledgeGeneral' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetMedia_dataFromXL : dataSetMedia} name='Медиа'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='media' value='media' />
          <ExcelColumn label='group' value='group' />
          <ExcelColumn label='CPP' value='CPP' />
          <ExcelColumn label='campaignType' value='campaignType' />
          <ExcelColumn label='coeff' value='coeff' />
          <ExcelColumn label='startBudget' value='startBudget' />
          <ExcelColumn label='finishBudget' value='finishBudget' />
          <ExcelColumn label='isChecked' value='isChecked' />
          <ExcelColumn label='k' value='k' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? datasetMediaDefault_dataFromXL : datasetMediaDefault} name='Media default'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='mediaDefault' value='media' />
          <ExcelColumn label='group' value='group' />
          <ExcelColumn label='CPP' value='CPP' />
          <ExcelColumn label='campaignType' value='campaignType' />
          <ExcelColumn label='coeff' value='coeff' />
          <ExcelColumn label='startBudget' value='startBudget' />
          <ExcelColumn label='finishBudget' value='finishBudget' />
          <ExcelColumn label='isChecked' value='isChecked' />
          <ExcelColumn label='k' value='k' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? datasetCopyStartMedia_dataFromXL : datasetCopyStartMedia} name='Copy Start Media'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='copyStartMedia' value='media' />
          <ExcelColumn label='group' value='group' />
          <ExcelColumn label='CPP' value='CPP' />
          <ExcelColumn label='campaignType' value='campaignType' />
          <ExcelColumn label='coeff' value='coeff' />
          <ExcelColumn label='startBudget' value='startBudget' />
          <ExcelColumn label='finishBudget' value='finishBudget' />
          <ExcelColumn label='isChecked' value='isChecked' />
          <ExcelColumn label='k' value='k' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetDonut_dataFromXL : dataSetDonut} name='Рекомендуемый медиа микс'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Media1' value='name' widthPx='100' />
          <ExcelColumn label='Budget' value='budget' />
          <ExcelColumn label='BudgetPr' value='budgetPr' />
          <ExcelColumn label='Group' value='group' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetOptic_dataFromXL : dataSetOptic} name='Сравнение различных медиамиксов'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Media3' value='name' />
          <ExcelColumn label='Group' value='group' />
          <ExcelColumn label='Option1' value='option1' />
          <ExcelColumn label='Option1Pr' value='option1Pr' />
          <ExcelColumn label='Option2' value='option2' />
          <ExcelColumn label='Option2Pr' value='option2Pr' />
          <ExcelColumn label='Option3' value='option3' />
          <ExcelColumn label='Option3Pr' value='option3Pr' />
          <ExcelColumn label='Option4' value='option4' />
          <ExcelColumn label='Option4Pr' value='option4Pr' />
          <ExcelColumn label='Option5' value='option5' />
          <ExcelColumn label='Option5Pr' value='option5Pr' />
          <ExcelColumn label='Option6' value='option6' />
          <ExcelColumn label='Option6Pr' value='option6Pr' />
          <ExcelColumn label='Option7' value='option7' />
          <ExcelColumn label='Option7Pr' value='option7Pr' />
          <ExcelColumn label='Option8' value='option8' />
          <ExcelColumn label='Option8Pr' value='option8Pr' />
          <ExcelColumn label='Option9' value='option9' />
          <ExcelColumn label='Option9Pr' value='option9Pr' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataSetReachOptic_dataFromXL : dataSetReachOptic} name='Охват_сравнение различных медиамиксов'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Reach' value='name' />
          <ExcelColumn label='Option1' value='option1' />
          <ExcelColumn label='Option2' value='option2' />
          <ExcelColumn label='Option3' value='option3' />
          <ExcelColumn label='Option4' value='option4' />
          <ExcelColumn label='Option5' value='option5' />
          <ExcelColumn label='Option6' value='option6' />
          <ExcelColumn label='Option7' value='option7' />
          <ExcelColumn label='Option8' value='option8' />
          <ExcelColumn label='Option9' value='option9' />
        </ExcelSheet>
        {chooseDATASETCONTR().map((dataset, index) => {
          return (
            // eslint-disable-next-line no-useless-concat
            <ExcelSheet key={index} data={dataset} name={'ВКМ' + `(${dataset[0].flightNumber} ${dataset[0].mainMedia})`}>
              <ExcelColumn label='flight' value='flight' />
              <ExcelColumn label='flightNumber' value='flightNumber' />
              <ExcelColumn label='MainMedia' value='mainMedia' />
              <ExcelColumn label='Media2' value='name' />
              <ExcelColumn label='Budget' value='budget' />
              <ExcelColumn label='BudgetPr' value='budgetPr' />
              <ExcelColumn label='Group' value='group' />
            </ExcelSheet>
          );
        })}
        <ExcelSheet data={flightsUpload ? dataSetGroupCheckedArr_dataFromXL : dataSetGroupCheckedArr} name='Group checkbox'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='groupCheckbox' value='name' />
          <ExcelColumn label='isChecked' value='isChecked' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataset_Media_group_reach_DataFromXL : dataset_Media_group_reach} name='Media_group_reach'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='media_group_reach' value='media_group_reach' />
          <ExcelColumn label='1+' value='1+' />
          <ExcelColumn label='2+' value='2+' />
          <ExcelColumn label='3+' value='3+' />
          <ExcelColumn label='4+' value='4+' />
          <ExcelColumn label='5+' value='5+' />
          <ExcelColumn label='6+' value='6+' />
          <ExcelColumn label='7+' value='7+' />
          <ExcelColumn label='8+' value='8+' />
          <ExcelColumn label='9+' value='9+' />
          <ExcelColumn label='10+' value='10+' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataset_Media_reach_DataFromXL : dataset_Media_reach} name='Media_reach'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='media_reach' value='media_reach' />
          <ExcelColumn label='1+' value='1+' />
          <ExcelColumn label='2+' value='2+' />
          <ExcelColumn label='3+' value='3+' />
          <ExcelColumn label='4+' value='4+' />
          <ExcelColumn label='5+' value='5+' />
          <ExcelColumn label='6+' value='6+' />
          <ExcelColumn label='7+' value='7+' />
          <ExcelColumn label='8+' value='8+' />
          <ExcelColumn label='9+' value='9+' />
          <ExcelColumn label='10+' value='10+' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataset_Total_reach_DataFromXL : dataset_Total_reach} name='Total_reach'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Total_reach' value='Total_reach' />
          <ExcelColumn label='1+' value='1+' />
          <ExcelColumn label='2+' value='2+' />
          <ExcelColumn label='3+' value='3+' />
          <ExcelColumn label='4+' value='4+' />
          <ExcelColumn label='5+' value='5+' />
          <ExcelColumn label='6+' value='6+' />
          <ExcelColumn label='7+' value='7+' />
          <ExcelColumn label='8+' value='8+' />
          <ExcelColumn label='9+' value='9+' />
          <ExcelColumn label='10+' value='10+' />
        </ExcelSheet>
        <ExcelSheet data={flightsUpload ? dataset_Total_cprp_DataFromXL : dataset_Total_cprp} name='Total_cprp'>
          <ExcelColumn label='flight' value='flight' />
          <ExcelColumn label='Total_cprp' value='Total_cprp' />
          <ExcelColumn label='1+' value='1+' />
          <ExcelColumn label='2+' value='2+' />
          <ExcelColumn label='3+' value='3+' />
          <ExcelColumn label='4+' value='4+' />
          <ExcelColumn label='5+' value='5+' />
          <ExcelColumn label='6+' value='6+' />
          <ExcelColumn label='7+' value='7+' />
          <ExcelColumn label='8+' value='8+' />
          <ExcelColumn label='9+' value='9+' />
          <ExcelColumn label='10+' value='10+' />
        </ExcelSheet>

        <ExcelSheet data={flightsUpload ? datasetCPPAllMonth_dataFromXL : datasetCPPAllMonth} name='CPPAllMonth'>
          <ExcelColumn label='CPPOrigin' value='CPPOrigin' />
          <ExcelColumn label='campaignType' value='campaignType' />
          <ExcelColumn label='coeff' value='coeff' />
          <ExcelColumn label='coeffImage' value='coeffImage' />
          <ExcelColumn label='coeffPromo' value='coeffPromo' />
          <ExcelColumn label='group' value='group' />
          <ExcelColumn label='isChecked' value='isChecked' />
          <ExcelColumn label='k' value='k' />
          <ExcelColumn label='media' value='media' />
          <ExcelColumn label='Август' value='Август' />
          <ExcelColumn label='Апрель' value='Апрель' />
          <ExcelColumn label='Декабрь' value='Декабрь' />
          <ExcelColumn label='Июль' value='Июль' />
          <ExcelColumn label='Июнь' value='Июнь' />
          <ExcelColumn label='Май' value='Май' />
          <ExcelColumn label='Март' value='Март' />
          <ExcelColumn label='Ноябрь' value='Ноябрь' />
          <ExcelColumn label='Октябрь' value='Октябрь' />
          <ExcelColumn label='Сентябрь' value='Сентябрь' />
          <ExcelColumn label='Февраль' value='Февраль' />
          <ExcelColumn label='Январь' value='Январь' />
        </ExcelSheet>

        <ExcelSheet data={flightsUpload ? datasetCopyCPPAllMonth_dataFromXL : datasetCopyCPPAllMonth} name='copyCPPAllMonth'>
          <ExcelColumn label='CPPCopy' value='CPPCopy' />
          <ExcelColumn label='campaignType' value='campaignType' />
          <ExcelColumn label='coeff' value='coeff' />
          <ExcelColumn label='coeffImage' value='coeffImage' />
          <ExcelColumn label='coeffPromo' value='coeffPromo' />
          <ExcelColumn label='group' value='group' />
          <ExcelColumn label='isChecked' value='isChecked' />
          <ExcelColumn label='k' value='k' />
          <ExcelColumn label='media' value='media' />
          <ExcelColumn label='Август' value='Август' />
          <ExcelColumn label='Апрель' value='Апрель' />
          <ExcelColumn label='Декабрь' value='Декабрь' />
          <ExcelColumn label='Июль' value='Июль' />
          <ExcelColumn label='Июнь' value='Июнь' />
          <ExcelColumn label='Май' value='Май' />
          <ExcelColumn label='Март' value='Март' />
          <ExcelColumn label='Ноябрь' value='Ноябрь' />
          <ExcelColumn label='Октябрь' value='Октябрь' />
          <ExcelColumn label='Сентябрь' value='Сентябрь' />
          <ExcelColumn label='Февраль' value='Февраль' />
          <ExcelColumn label='Январь' value='Январь' />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};
